<template>
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">中心艺术家</div>
      <div class="list-group p-0">
        <a href="#" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心艺术家</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="text-left h3">中心艺术家</div>
      <div class="text-left mt-md-5 w-100 row">
        <div class="col-md-2 p-0" v-for="(item,index) in peoListByClassData" :key="index">
          <div class="card-body pr-0">
            <div class="card">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById(item.peoId)"><img class="w-100 m-0" :src="require('../../../static/image/people/' + item.peoPhoto + '.jpg')"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 p-1 text-center font-weight-bold">{{ item.peoName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "team",
  data() {
    return {
      peoListByClassData: []
    }
  },
  mounted() {
    this.getPeoListByClass(2)
  },
  methods: {
    artistById(peoId) {
      this.$router.push({
        path: '/artistByid/',
        query: { peoId: peoId}
      })
    },
    getPeoListByClass(peoClass) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getPeoList/' + peoClass
      }).then((res) =>{
        this.peoListByClassData = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>